import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/onion"
  },
  {
    path: '/onion',
    name: 'onion',
    component: () => import('@/views/onion'),
    meta: {
      title: '',
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
